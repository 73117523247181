/** @format */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  comisionPorcentaje: 100,
  // keyStripe:
  //   "pk_test_51K1pROKmszbZDo9rwJi4WpUij4CPGxaIC4QjHCcE5cAwKW6wJY3zCD4YGAOOYBE5WPOKM3wHnAt31v4EcF8Mpw3l00R346ADM1",

  keyStripe:
    "pk_live_51N2VulC811O4UepkNrfGOOz5DWwNDjRJ7RQ21n4bta73XBc62nAy9cl502JkdMyjTI33ZZOM0Ki15Le9EubrVXT800YS2Iha35",

  // urlBase1: 'https://api-alfa.youetix.com/api/v1/',
  // urlBase2: 'https://api-alfa.youetix.com/api/v2/',
  // urlBaseGateway: 'https://beta-gateway.youetix.com/api/',

  // TyC: 'https://youetix.com/#/terms-and-conditions',
  // PdP: 'https://youetix.com/#/service-policies',

  // urlEtixmall: 'https://etixmall.com/'

  urlBase1: "https://api.youetix.com/api/v1/",
  urlBase2: "https://api.youetix.com/api/v2/",
  urlBaseGateway: "https://gateway.youetix.com/api/",

  TyC: "https://youetix.com/#/terms-and-conditions",
  PdP: "https://youetix.com/#/service-policies",

  urlEtixmall: "https://etixmall.com/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
